<template>
  <div>
    <v-dialog v-model="show" max-width="600px">
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="blue darken-2" dark
               v-bind="attrs"
               v-on="on"
        >
          Edit Profile
        </v-btn>
      </template>
      <v-card>
        <v-card-title>
          <h5>Update Password</h5>
        </v-card-title>
        <v-card-text>
          <v-text-field
              filled
              type="email"
              label="Email"
              v-model="user.email"
              :readonly="true"
          />
          <v-text-field
              filled
              v-model="current_pw"
              :type="current_pw_type"
              label="Current Password"
              :append-icon="current_pw_type === 'text' ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="showPassword('current_pw')"
          />
          <v-text-field
              filled
              v-model="new_pw"
              :type="new_pw_type"
              label="New Password"
              :rules="new_pw_rules"
              :append-icon="new_pw_type === 'text' ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="showPassword('new_pw')"
          />
          <v-text-field
              filled
              v-model="confirm_pw"
              :type="conf_pw_type"
              label="Confirm New Password"
              :rules="conf_new_rules"
              :append-icon="conf_pw_type === 'text' ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="showPassword('conf_pw')"
          />
          <v-card-actions>
            <v-btn color="red darken-2" dark class="float-left" @click="updatePassword">Submit</v-btn>
          </v-card-actions>
        </v-card-text>

      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "EditPassword",
  data() {
    return {
      show: false,
      current_pw_type: 'password',
      new_pw_type: 'password',
      conf_pw_type: 'password',
      new_pw_rules: [
        value => this.isPassValid(value) || 'New password must be at least 8 characters, contains at least 1 capital letter and 1 number'
      ],
      conf_new_rules: [
        value => this.checkSimilar(value) || 'New Password and Confirm New Password do not match.'
      ],
      current_pw: '',
      new_pw: '',
      confirm_pw: ''
    }
  },
  computed: {
    user() {
      return this.$store.getters.getUser
    }
  },
  methods: {
    showPassword(from) {
      if (from === 'new_pw') {
        if (this.new_pw_type === 'password') {
          this.new_pw_type = 'text'
        } else {
          this.new_pw_type = 'password'
        }
      } else if (from === 'conf_pw') {
        if (this.conf_pw_type === 'password') {
          this.conf_pw_type = 'text'
        } else {
          this.conf_pw_type = 'password'
        }
      } else {
        if (this.current_pw_type === 'password') {
          this.current_pw_type = 'text'
        } else {
          this.current_pw_type = 'password'
        }
      }
    },
    isPassValid(val) {
      return val ? /^(?=.*[A-Z])(?=.*\d).{8,}$/.test(val) : false
    },
    checkSimilar(val) {
      return val ? this.isPassValid(val) && val === this.new_pw : false
    },
    updatePassword() {
      let submit = {
        email: this.user.email,
        new_pw: this.new_pw,
        confirm_pw: this.confirm_pw,
        current_pw: this.current_pw
      }
      if (submit.new_pw.length >= 0 && submit.current_pw.length >= 0 && submit.confirm_pw.length >= 0) {
        if (this.checkSimilar(submit.confirm_pw) && this.isPassValid(submit.new_pw)) {
          this.$store.dispatch('changePassword', submit)
              .then(res => {
                alert(res)
                this.show = false
              })
              .catch(err => {
                alert(err)
              })
        } else {
          alert("New password must be at least 8 characters, contains at least 1 capital letter and 1 number")
          alert("New Password and Confirm New Password do not match.")
        }
      } else {
        alert("Please fill in all fields.")
      }
    }
  }
}
</script>

<style scoped>

</style>